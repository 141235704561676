import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useCookies, CookiesProvider } from 'react-cookie';
import { func, shape } from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { Layout } from '@/components';
import createEmotionCache from '../utils/createEmotionCache';
import { SegmentPageListener } from '../modules/segment';
import theme from '../theme';
import './app.css';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

function App({ Component, pageProps, emotionCache = clientSideEmotionCache }) {
  const { page, metadata } = pageProps;

  const [cookies] = useCookies(['gbIdentity']);
  useEffect(() => {
    if (cookies?.gbIdentity?.experimentKey) {
      // Initialize mix panel
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY, {
        loaded: mx => {
          mx.alias(String(cookies?.gbIdentity?.id), mx.get_distinct_id());
        },
      });

      mixpanel.track('Experiment Viewed', {
        'Experiment Id': String(cookies?.gbIdentity?.experimentKey),
        'Variant Id': String(cookies?.gbIdentity?.variationId),
      });
    }
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <SegmentPageListener>
            <Layout {...page} metadata={metadata}>
              <Component {...pageProps} />
            </Layout>
          </SegmentPageListener>
        </ThemeProvider>
      </CookiesProvider>
    </CacheProvider>
  );
}

App.propTypes = {
  Component: func.isRequired,
  emotionCache: shape({}).isRequired,
  pageProps: shape({}).isRequired,
};

export default App;
