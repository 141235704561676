import { palette } from '../../global';

export default {
  styleOverrides: {
    notchedOutline: {
      borderRadius: '7px',
      border: '2px solid',
      borderColor: palette.neutral.light,
    },
  },
};
