import dynamic from 'next/dynamic';

const Banner = dynamic(() => import('./Banner'));
const Breadcrumb = dynamic(() => import('./Breadcrumb'));
const BlockWrapper = dynamic(() => import('./BlockWrapper'));
const Footer = dynamic(() => import('./Footer'));
const Header = dynamic(() => import('./Header'));
const Hero = dynamic(() => import('./Hero'));
const Layout = dynamic(() => import('./Layout'));
const Link = dynamic(() => import('./Link'));
const Meta = dynamic(() => import('./Meta'));
const Chatbot = dynamic(() => import('./Chatbot'));

export { Banner, Breadcrumb, BlockWrapper, Footer, Header, Hero, Layout, Link, Meta, Chatbot };
