import { palette } from '../../global';

export default {
  styleOverrides: {
    root: {
      '&:hover': {
        color: palette.primary.main,
      },
    },
  },
};
