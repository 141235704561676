import { defaultTheme, palette, mixins } from '../../global';

export default {
  styleOverrides: {
    root: {
      borderRadius: 80,
      height: 48,
      padding: defaultTheme.spacing(0, 5),
      textTransform: 'none',
      width: '100%',
    },
    outlinedPrimary: {
      color: palette.neutral.darkest,
      border: `2px solid ${palette.primary.main}`,
      '&:hover': {
        background: mixins.gradient(palette.neutral.light, palette.neutral.lightest),
        borderWidth: 2,
      },
    },
    outlinedSecondary: {
      color: palette.neutral.darkest,
      border: `2px solid ${palette.secondary.main}`,
      '&:hover': {
        background: mixins.gradient(palette.neutral.light, palette.neutral.lightest),
        borderWidth: 2,
      },
    },
    containedPrimary: {
      color: palette.neutral.lightest,
      background: mixins.gradient(palette.primary.light, palette.primary.main),
      '&:hover': {
        background: mixins.gradient(
          // This is gradient because it flashes white switching from gradient to non gradient
          palette.primary.dark,
          palette.primary.dark,
        ),
      },
    },
    containedSecondary: {
      background: mixins.gradient(palette.secondary.light, palette.secondary.main),
      color: palette.neutral.lightest,
      '&:hover': {
        background: mixins.gradient(
          // This is gradient because it flashes white switching from gradient to non gradient
          palette.secondary.dark,
          palette.secondary.dark,
        ),
      },
    },
  },
};
