import { defaultTheme, palette } from '../../global';

export default {
  styleOverrides: {
    root: {
      [defaultTheme.breakpoints.up('md')]: {
        paddingLeft: defaultTheme.spacing(5),
        paddingRight: defaultTheme.spacing(5),
      },
      '&:hover': {
        color: palette.primary.main,
        backgroundColor: palette.neutral.lighter,
      },
    },
  },
};
