export default {
  styleOverrides: {
    endAdornment: {
      top: 'auto',
    },
    option: {
      fontSize: 18,
    },
    noOptions: {
      fontSize: 18,
    },
  },
};
