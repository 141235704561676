import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { mixins, shadows, typography, palette } from './global';
import components from './components';

const theme = createTheme({
  mixins,
  shadows,
  typography,
  palette,
  components,
});

export default responsiveFontSizes(theme);
