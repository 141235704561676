export default {
  text: {
    primary: '#233152',
    secondary: '#747C82',
    default: '#B6B8BB',
  },
  primary: {
    light: '#3DDB93',
    main: '#16D3A9',
    dark: '#0F8D71',
  },
  secondary: {
    light: '#CDDBFB',
    main: '#6994F4',
    dark: '#4663A3',
    darkest: '#001945',
  },
  neutral: {
    lightest: '#FFFFFF',
    lighter: '#F5F7F9',
    light: '#E1EAF1',
    medium: '#747C82',
    darkest: '#3E454B',
  },
  accent: {
    light: '#FDC2CC',
    main: '#FA4767',
    dark: '#A72F45',
  },
  error: {
    main: '#FA4767',
  },
  warning: {
    main: '#F57F17',
  },
};
